/* GLOBAL FONT STYLES */
@font-face {
	font-family: 'Noe Display Bold';
	src: url(../assets/font/NoeDisplayBold.otf) format("opentype");
  }
  
a {
	text-decoration: none;
	color: inherit;
	&.link {
		border-bottom: 1px solid currentColor;
		font-weight: bolder;
		padding-bottom: 5px;
	}
}

.is-bold,
strong {
	font-weight: bold !important;
}
.is-italic,
i,
em {
	font-style: italic !important;
}
.text-uppercase {
	text-transform: uppercase;
}
.is-big {
	font-size: 3rem;
}

.is-noe-display {
	font-family: 'Noe Display Bold', sans-serif !important;
	color: #0E1438;
}