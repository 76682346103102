/**
  Custom Colors directly reusable in html
 */

$white: rgb(250, 250, 250);

mat-disabled {
	color: rgb(179, 179, 179);
}

mat-chip.mat-purple {
	color: $white !important;
	background: #a58ad5 !important;
}
mat-chip.mat-red {
	color: $white !important;
	background: #D62828 !important;
}
mat-chip.mat-pink {
	color: $white !important;
	background: #ef5d8e !important;
}
mat-chip.mat-dark-blue {
	color: $white !important;
	background: #7481c9 !important;
}
mat-chip.mat-light-blue {
	color: $white !important;
	background: #47cfe0 !important;
}
mat-chip.mat-teal {
	color: $white !important;
	background: #76F68A !important;
}
mat-chip.mat-light-green {
	color: $white !important;
	background: #C8FBD0 !important;
}
mat-chip.mat-yellow {
	color: #666 !important;
	background: #ffd248 !important;
}
mat-chip.mat-orange {
	color: $white !important;
	background: #ff855f !important;
}
mat-chip.mat-brown {
	color: $white !important;
	background: #9e847a !important;
}
mat-chip.mat-gray {
	color: #888 !important;
	background: #cccccc !important;
}
mat-chip.mat-dark-gray {
	color: #cccccc !important;
	background: #8ca1ab !important;
}
mat-chip.mat-white {
	background: white;
	color: #666666;
}

mat-icon.mat-purple {
	color: #a58ad5 !important;
}
mat-icon.mat-red {
	color: #f16d6a !important;
}
mat-icon.mat-pink {
	color: #ef5d8e !important;
}
mat-icon.mat-dark-blue {
	color: #7481c9 !important;
}
mat-icon.mat-light-blue {
	color: #47cfe0 !important;
}
mat-icon.mat-teal {
	color: #C8FBD0 !important;
}
mat-icon.mat-light-green {
	color: #7dc580 !important;
}
mat-icon.mat-yellow {
	color: #ffd248 !important;
}
mat-icon.mat-orange {
	color: #ff855f !important;
}
mat-icon.mat-brown {
	color: #9e847a !important;
}
mat-icon.mat-gray {
	color: #cccccc !important;
}
mat-icon.mat-disabled {
	color: #9a9a9a !important;
}
mat-icon.mat-dark-gray {
	color: #8ca1ab !important;
}
mat-icon.mat-white {
	color: white;
}
