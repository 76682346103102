green-user-board,
green-admin-board {
	& > main#page-container {
		display: flex;
		flex: 1;
		& > *:not(router-outlet) {
			display: flex;
			flex-direction: column;
			flex: 1;
			width: 100%;
			max-height: calc(100vh - 56px);
			& .user-view {
				display: flex;
				flex-direction: column;
				flex: 1;
				height: 100%;
				overflow-y: auto;
				& .content {
					flex: 1;
					overflow-y: auto;
				}
			}
		}
	}
}

#explanation {
	gap: 15px;
	mat-card {
		width: 390px;
		margin: 0.5em auto;
		padding-bottom: 1rem;
		mat-icon {
			font-size: 5rem;
			width: 5rem;
			height: 5rem;
		}
		h2 {
			font-size: 1.15rem;
		}
		p {
			padding: 1em;
			color: #777;
			text-justify: justify;
		}
	}
}
