/* GLOBAL LAYOUT STYLES */

/* GLOBAL SELECTION STYLES */
::-moz-selection {
	background: #ADFAB9;
	color: white;
}
::selection {
	background: #ADFAB9;
	color: white;
}

/* GLOBAL SCROLLBARS STYLES */
::-webkit-scrollbar {
	width: 8px;
	z-index: 0;
}

/* scrollbar width */
::-webkit-scrollbar-track {
	background-color: #F2FFF4;
	border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-thumb {
	background: #ADFAB9;
	border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb:hover {
	background: darken(#ADFAB9, 10);
}

* {
	margin: 0;
	padding: 0;
}
body {
	font-family: var(--font-family), sans-serif !important;
}

button,
a {
	min-width: fit-content !important;
}
table {
	width: 100%;
}
router-outlet {
	display: none;
}
