/*DRAG AND DROP STYLE */

.ngx-file-drop__drop-zone {
	width: 80%;
	margin: 0 auto;
	border: none !important;
	border-radius: 5px !important;
	box-shadow: -1px 2px 30px 3px rgba(0, 0, 0, 0.3) inset;
	height: auto !important;
}

.ngx-file-drop__content {
	//min-height: 300px !important;
	padding: 1.5rem 0.5rem 1rem;
	flex-direction: column;
	gap: 0.5em;
	height: auto !important;
}
