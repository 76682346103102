/**
TABLE
 */
.mat-sort-header-container {
	display: inline-flex !important;
}
td.mat-cell,
.mat-header-cell {
	padding: 0.5em !important;
}

.cell-inactive {
	opacity: 0.5;
}

table,
.table-wrap {
	width: 100%;
	margin-inline: auto;
}
.table-wrap {
	border-radius: 10px;
	overflow: hidden;
	width: 100%;
	min-width: fit-content;
	&.scrolled-y {
		overflow-y: auto;
	}
	&.scrolled-x {
		overflow-x: auto;
		max-width: 90vw;
		min-width: inherit;
	}
	mat-card#table-grouped-actions {
		min-height: 80px;
		width: 100%;
		padding-inline: 0.5em;
		border-radius: 10px 10px 0 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	table {
		.mat-form-field-infix {
			min-width: 84px;
		}
		.mat-cell,
		.mat-header-cell {
			padding: 1em 1.25em 0.5em 1.25em !important;
		}
		.mat-header-cell {
			font-weight: bold;
		}
	}
}
.dark .table-wrap {
	mat-card.table-grouped-actions {
		background-color: darken(#424242, 5%);
	}
}
