// Custom Overrides for Angular Material
@use '@angular/material' as mat;

.cdk-overlay-pane {
	min-width: fit-content;
	max-width: 90vw;
}

/**
SNACKBAR
 */
mat-snack-bar-container {
	// Defines shared SnackBar Global custom styles
	margin-top: 70px !important;
	margin-right: 15px !important;
}

/**
BUTTONS
 */
.mat-ink-bar {
	display: none;
}
.mat-chip-list-wrapper {
	justify-content: space-evenly !important;
} // Fixing default Material behaviour
.mat-fab .mat-button-wrapper {
	padding: 4px 0 !important;
} // Fixing default Material behaviour
.mat-mdc-button-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-grow: 1;
	min-height: 32px;
	padding: 0 !important;
}
.stretched > .mat-button-wrapper {
	justify-content: stretch;
	text-align: left;
}

*:has(> .mat-mdc-button-disabled) {
	cursor: not-allowed;
}
.mat-mdc-button-disabled {
	pointer-events: none;
}

.mdc-icon-button {
	display: inline-flex !important;
	align-items: center;
	justify-content: center;
	padding: 0 !important;
}

button#user-menu {
	span.mdc-button__label {
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		gap: 10px;
		padding-inline: 0.25rem;
	}
}

/**
STEPPER
 */
.mat-step-icon-content {
	display: flex;
}
#report-stepper .mat-step-header {
	pointer-events: none !important;
}

/** ICONS **/
mat-icon {
	font-family: 'Material Symbols Outlined';
	direction: ltr;
	font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
	font-size: 1.125em;
	display: inline-flex !important;
	justify-content: center;
	align-items: center;

	&.xs {
		width: 0.85rem !important;
		height: 0.85rem !important;
		font-size: 0.85rem;
		line-height: 0.85rem;
	}
	&.xsm {
		width: 1.25rem !important;
		height: 1.25rem !important;
		font-size: 1.25rem;
		line-height: 1.25rem;
	}
	&.sm {
		width: 2rem !important;
		height: 2rem !important;
		font-size: 2rem;
		line-height: 2rem;
	}
	&.smd {
		width: 2.5rem !important;
		height: 2.5rem !important;
		font-size: 2.5rem;
		line-height: 2.5rem;
	}
	&.md {
		width: 3.5rem !important;
		height: 3.5rem !important;
		font-size: 3.5rem;
		line-height: 3.5rem;
	}
	&.lg {
		width: 5rem !important;
		height: 5rem !important;
		font-size: 5rem;
		line-height: 5rem;
	}
	&.xl {
		width: 6.5rem !important;
		height: 6.5rem !important;
		font-size: 6.5rem;
		line-height: 6.5rem;
	}
	&.xxl {
		width: 8rem !important;
		height: 8rem !important;
		font-size: 8rem;
		line-height: 8rem;
	}
}

.mat-mdc-button-base mat-icon {
	line-height: 1 !important;
}

.mdc-button  {
	border-radius: 25px !important;
}

/** CARDS **/
.mat-mdc-dialog-content {
	padding-bottom: 0 !important;
	overflow-y: clip !important;
}

.mat-mdc-dialog-actions {
	justify-content: space-evenly !important;
}

/** TABS **/
mat-tab-group {
	height: 100%;
	.mat-mdc-tab-body-wrapper {
		flex: 1;
	}
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
	overflow: inherit !important;
}

.mat-mdc-tab-body-content {
	overflow: initial !important;
}

/** OVERLAY **/
.mat-mdc-menu-content {
	padding-block: 0 !important;
}

/** TABLE **/
.mat-mdc-cell {
	overflow: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 25px !important;
	max-height: 65vh;
	overflow: hidden;
}

.mat-expansion-panel {
	border-radius: 25px !important;
	background-color: #F6F6F6 !important;
}

.dark .mat-expansion-panel {
	background-color: #3E4360 !important;
}


.mat-mdc-dialog-container {
	--mdc-dialog-container-color: #F6F6F6 !important; 
 }

 .dark .mat-mdc-dialog-container {
	--mdc-dialog-container-color: #3E4360 !important; 
 }

 .dark .mat-mdc-menu-panel {
	background-color: #3E4360 !important;
 }
