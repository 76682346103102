/* UTILITY STYLES */
.spaced {
	padding: 1.25rem 2rem !important;
	@media (max-width: 640px) {
		padding: 0.75rem 1rem !important;
	}
	&-x {
		padding: 0 2rem !important;
		@media (max-width: 640px) {
			padding: 0 1rem !important;
		}
		&-lg {
			padding: 0 4rem !important;
			@media (max-width: 640px) {
				padding: 0 2rem !important;
			}
		}
		&-xs {
			padding: 0 1rem !important;
			@media (max-width: 640px) {
				padding: 0 0.5rem !important;
			}
		}
	}
	&-y {
		padding: 1.25rem 0 !important;
		@media (max-width: 640px) {
			padding: 0.75rem 0 !important;
		}
		&-lg {
			padding: 2.5rem 0 !important;
			@media (max-width: 640px) {
				padding: 1.25rem 0 !important;
			}
		}
		&-xs {
			padding: 0.65rem 0 !important;
			@media (max-width: 640px) {
				padding: 0.35rem 0 !important;
			}
		}
	}
	&-lg {
		padding: 2.5rem 4rem !important;
		@media (max-width: 640px) {
			padding: 1.25rem 2rem !important;
		}
	}
	&-xs {
		padding: 0.65rem 1rem !important;
		@media (max-width: 640px) {
			padding: 0.35rem 0.5rem !important;
		}
	}
	&-xxs {
		padding: 0.25rem 0.5rem !important;
		@media (max-width: 640px) {
			padding: 0.12rem 0.2rem !important;
		}
	}
}

.spaceless {
	padding: 0 !important;
}
.no-margin {
	margin: 0 !important;
}
.legend {
	color: #aaa;
	font-style: italic;
}
.centered {
	text-align: center !important;
}
.disabled {
	cursor: not-allowed;
}
.helped {
	cursor: help;
}
.pointed {
	cursor: pointer;
}
.bordered {
	border: 1px solid currentColor !important;
}

.row {
	width: 100%;
}

.background-image-centered {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
