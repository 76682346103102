// Custom Theming for Angular Material
@use '@angular/material' as mat;

$blue: (
	50: #E7E8EC,
    100: #CFD0D7,
    200: #B7B9C4,
    300: #9FA1AF,
    400: #86899B,
    500: #0E1438, // The base color `#0E1438` is darker, placed at 700
    600: #575B74,
    700: #3E4360,
    800: #272C4C,
    900: #0E1438,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #ffffff,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #ffffff,
		A400: #ffffff,
		A700: #ffffff,
	),
);
$green: (
	50: #edfff3,   // Very light for high contrast
    100: #d1ffdf,  // Lighter, subtle green
    200: #a9fdbd,  // Distinct but soft
    300: #7efc9d,  // Mid-range light
    400: #56fa80,  // Noticeably more vibrant
    500: #3fe06a,  // Slightly darker than the original `#76F68A` for contrast
    600: #32b659,  // Higher contrast for darker elements
    700: #259046,  // Darker green for text or accent use
    800: #196833,  // More contrast and depth
    900: #0e4220,  // Darkest shade for strong contrast
    A100: #aeffbe, // Brighter accent for high contrast
    A200: #7bff92, // Vibrant, saturated green
    A400: #49ff66, // Bright and bold
    A700: #2fff4a, // Maximum vibrance for striking accent
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #ffffff,
		A400: #ffffff,
		A700: #ffffff,
	),
);
$red: (
	// Defines Warnings Palette color
	50: #fee8e7,
	100: #fdc6c4,
	200: #fca19d,
	300: #fb7b75,
	400: #fa5e58,
	500: #f9423a,
	600: #f83c34,
	700: #f7332c,
	800: #f62b25,
	900: #D62828,
	A100: #ffffff,
	A200: #fff3f3,
	A400: #ffc1c0,
	A700: #ffa8a7,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #fff,
		500: #fff,
		600: #fff,
		700: #fff,
		800: #fff,
		900: #fff,
		A100: #000000,
		A200: #fff,
		A400: #fff,
		A700: #fff,
	)
);
$stroke: (
	50: #f7f6fe,   // Very light, almost white lavender
    100: #eceaff,  // Light lavender for subtle backgrounds
    200: #d7d4fc,  // Softer purple
    300: #b8b5f7,  // Lighter purple
    400: #9996f3,  // More prominent lavender
    500: #CBC1F6,  // Base lavender, slightly darker for contrast
    600: #6665d4,  // Higher contrast, darker purple
    700: #504eb7,  // Darker purple for emphasis
    800: #39388f,  // Darker shade for more contrast
    900: #272660,  // Deepest purple, high contrast
    A100: #ded1ff, // Bright and vibrant accent
    A200: #b5a4ff, // Saturated lavender for emphasis
    A400: #8b75ff, // Bold, striking accent color
    A700: #7059ff,  // Most vibrant for maximum contrast
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000,
	),
);
$primary: mat.define-palette($blue);
$primary-dark: mat.define-palette($stroke);
$accent: mat.define-palette($green);
$accent-dark: mat.define-palette($stroke);
$warn: mat.define-palette($red);

// Typography
$typography-config: mat.define-typography-config(
	$font-family: 'Inter',
);
@include mat.core();

// Light Theme
$greencoder-light-theme: mat.define-light-theme(
	(
		color: (
			primary: $primary,
			accent: $accent,
			warn: $warn,
		),
		typography: $typography-config,
	)
);
@include mat.all-component-themes($greencoder-light-theme);

// Dark Theme
$greencoder-dark-theme: mat.define-dark-theme(
	(
		color: (
			primary: $primary-dark,
			accent: $accent,
			warn: $warn,
		),
		typography: $typography-config,
	)
);

/* DARK MODE */
.dark {
	@include mat.all-component-colors($greencoder-dark-theme);
	.text-primary {
		color: mat.get-color-from-palette($primary-dark) !important;
	}
	.bg-primary {
		background-color: mat.get-color-from-palette($primary-dark) !important;
	}
}

/* CUSTOM COLORS */
.is-accent {
	color: mat.get-color-from-palette($accent) !important;
} // Export accent color as reusable class in project stylesheets
.is-accent-bg {
	background-color: mat.get-color-from-palette($accent) !important;
}
.is-primary {
	color: mat.get-color-from-palette($primary) !important;
} // Export primary color as reusable class in project stylesheets
.is-primary-bg {
	background-color: mat.get-color-from-palette($primary) !important;
}
.dark .is-primary {
	color: mat.get-color-from-palette($primary-dark) !important;
} // Export primary color as reusable class in project stylesheets
.dark .is-primary-bg {
	background-color: mat.get-color-from-palette($primary-dark) !important;
}
.is-warn {
	color: mat.get-color-from-palette($warn) !important;
} // Export warning color as reusable class in project stylesheets
.is-success {
	color: #26da35 !important;
} // Export success color as reusable class in project stylesheets
.is-orange {
	color: #ff6900 !important;
}
.is-disabled {
	color: #9a9a9a !important;
}
.is-accent-border {
	border-style: solid;
	border-width: 3px;
	border-color: mat.get-color-from-palette($accent) !important;
}

.is-active {
	color: #76F68A !important;
}

body.dark green-lazy-img #skeleton {
	background: #0E1438;
}

body {
	background-color: #E7E8EC;
}