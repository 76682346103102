/* GLOBAL FORMS RULES */
form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: fit-content;
	margin-inline: auto;
	.mat-mdc-form-field {
		flex-grow: 1;
		margin-inline: auto;
	}
	section.wrapped {
		flex-grow: 1;
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		place-content: center space-between;
		align-items: center;
		gap: 1rem;
		&.centered {
			place-content: center space-evenly;
		}
	}
}

/** MATERIAL OVERRIDES **/

mat-form-field.no-subscript {
	.mat-mdc-form-field-subscript-wrapper {
		display: none !important;
	}
}

mat-form-field.absolute-subscript {
	position: relative;
	.mat-mdc-form-field-subscript-wrapper {
		position: absolute;
		bottom: -20px;
		z-index: 1000;
	}
}

mat-form-field.transparent {
	.mat-mdc-text-field-wrapper {
		background-color: transparent !important;
	}
}

mat-form-field.no-border-bottom {
	.mdc-line-ripple {
		display: none;
	}
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
	position: relative !important;
	mat-error {
		padding-block: 0.25rem;
	}
}

mat-form-field.ng-invalid:has(mat-error)
	.mat-mdc-form-field-bottom-align::before {
	display: none;
}

.mdc-checkbox {
	.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
			[data-indeterminate='true']
		)
		~ .mdc-checkbox__background {
		border-color: currentColor !important;
		background: rgba(255, 255, 255, 0.85) !important;
	}
}

.mat-mdc-checkbox .mdc-form-field {
	color: currentColor !important;
}

.mdc-dialog__actions {
	padding: 0.5rem 0.75rem 0.75rem !important;
}

/* Phone Input */
.ngx-mat-input-tel-mat-menu-panel .mat-mdc-menu-content {
	height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
	input {
		color: #444;
	}
}

.ngx-mat-input-tel-container {
	.country-selector-code {
		display: none;
	}
}
